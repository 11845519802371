import { createClient } from '@sanity/client'

import React, { createContext, useState } from 'react'

import { User, useAuth0 } from '@auth0/auth0-react'

import { SanityUserRoles, SanityUserType } from '@contracts/Sanity/SanityUser'

type UserContextType = {
	currentUser: UserType
	isUserLoading: boolean
	currentUserRoles: SanityUserRoles[]
	emailVerified: EmailVerifiedType
}

type UserType = User | null | false

type EmailVerifiedType = boolean | null | undefined

export const UserContext = createContext<UserContextType>({
	currentUser: null,
	isUserLoading: false,
	currentUserRoles: [],
	emailVerified: null,
})

type Props = {
	children: React.ReactNode
}

export const UserProvider: React.FC<Props> = ({ children }) => {
	const [currentUser, setCurrentUser] = useState<UserType>(null)
	const [currentUserRoles, setCurrentUserRoles] = useState<SanityUserRoles[]>([])
	const [isUserLoading, setIsUserLoading] = useState(true)
	const [emailVerified, setEmailVerified] = useState<EmailVerifiedType>(null)

	const { user, isLoading } = useAuth0()

	const client = createClient({
		projectId: process.env.GATSBY_SANITY_PROJECT_ID,
		dataset: process.env.GATSBY_SANITY_PROJECT_DATASET,
		useCdn: true, // set to `false` to bypass the edge cache
		apiVersion: '2023-05-03', // use current date (YYYY-MM-DD) to target the latest API version
		token: process.env.GATSBY_SANITY_READ_TOKEN, // Only if you want to update content with the client
	})

	React.useMemo(async () => {
		// If auth0 is loading, set everything to null
		if (isLoading) {
			setCurrentUser(null)
			setIsUserLoading(true)
			setCurrentUserRoles([])
			setEmailVerified(null)

			return
		}

		// Now we have a user, fetch the roles from Sanity
		if (user) {
			await client
				.fetch<SanityUserType[]>(`*[_type == "user" && auth0Id == "${user.sub}"]`)
				.then((data) => {
					if (data.length == 1) {
						const sanityUser = data[0]

						setCurrentUserRoles(sanityUser.roles)
					} else {
						setCurrentUser(false)
						setIsUserLoading(false)
						setCurrentUserRoles([])
						setEmailVerified(false)
					}
				})

			setCurrentUser(user)
			setIsUserLoading(false)
			setEmailVerified(user.email_verified)

			return
		} else {
			setCurrentUser(false)
			setIsUserLoading(false)
			setCurrentUserRoles([])
			setEmailVerified(false)

			return
		}
	}, [user, isLoading])

	return (
		<UserContext.Provider
			value={{ currentUser, currentUserRoles, isUserLoading, emailVerified }}
		>
			{children}
		</UserContext.Provider>
	)
}
