exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-register-tsx": () => import("./../../../src/pages/register.tsx" /* webpackChunkName: "component---src-pages-register-tsx" */),
  "component---src-pages-unauthorized-tsx": () => import("./../../../src/pages/unauthorized.tsx" /* webpackChunkName: "component---src-pages-unauthorized-tsx" */),
  "component---src-pages-verify-email-tsx": () => import("./../../../src/pages/verify-email.tsx" /* webpackChunkName: "component---src-pages-verify-email-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/Page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "slice---src-components-layout-footer-tsx": () => import("./../../../src/components/Layout/Footer.tsx" /* webpackChunkName: "slice---src-components-layout-footer-tsx" */),
  "slice---src-components-layout-header-tsx": () => import("./../../../src/components/Layout/Header.tsx" /* webpackChunkName: "slice---src-components-layout-header-tsx" */)
}

