import React, { createContext, useState } from 'react'

type MenuContextType = {
	isOpen: boolean
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export const MenuContext = createContext<MenuContextType>({
	isOpen: false,
	setIsOpen: () => null,
})

type Props = {
	children: React.ReactNode
}

export const MenuProvider: React.FC<Props> = ({ children }) => {
	const [isOpen, setIsOpen] = useState(false)

	return <MenuContext.Provider value={{ isOpen, setIsOpen }}>{children}</MenuContext.Provider>
}
