import React from 'react'

import { GatsbyBrowser } from 'gatsby'

import { Auth0Provider } from '@auth0/auth0-react'

import { MenuProvider } from './src/context/MenuProvider'
import { UserProvider } from './src/context/UserProvider'
import './src/styles/global.css'

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => {
	return (
		<Auth0Provider
			domain={process.env.GATSBY_AUTH0_DOMAIN as string}
			clientId={process.env.GATSBY_AUTH0_CLIENT_ID as string}
			authorizationParams={{
				redirect_uri: process.env.GATSBY_AUTH0_REDIRECT_URI,
			}}
		>
			<UserProvider>
				<MenuProvider>{element}</MenuProvider>
			</UserProvider>
		</Auth0Provider>
	)
}
